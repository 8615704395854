<template>
  <div :class="{disabled: disabled}">
    <a-form-model layout="vertical" :model="form"
                  :rules="rules" ref="form">
      <a-card class="card" title="业务信息" :bordered="false">
        <div slot="extra">
          <a-affix :offsetTop="50" :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right'}">
            <a-button><router-link :to="{ name: 'Logistics'}">取消</router-link></a-button>
            <a-divider type="vertical" />
            <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
          </a-affix>
        </div>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="订单编号">
              <a-input v-model="form.serial_num" disabled placeholder="保存后自动生成"/>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="报价编号" prop="quota_num">
              <a-input v-model="form.quota_num" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="委托单位">
              <a-select
                showSearch
                label-in-value
                :filter-option="false"
                allowClear
                placeholder="请选择委托单位"
                style="width: 100%"
                :value="customerInfo"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="客户业务编号">
              <a-input v-model="form.customer_num"/>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="进口方">
              <a-input v-model="form.importer"/>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="出口方">
              <a-input v-model="form.exporter"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card class="card" title="车货信息" :bordered="false">
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="总件数">
              <a-input-group compact>
                <a-input-number style="width: 40%" v-model="form.total_num"/>
                <a-auto-complete
                  v-model="form.unit"
                  style="width: 60%"
                  placeholder="请选择包装方式"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in packageTypeOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-input-group>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="总毛重">
              <a-input suffix="KGS" v-model="form.weight"/>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="总体积">
              <a-input suffix="m³" v-model="form.volume"/>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-item label="总天数">
              <a-input v-model="form.total_day"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="报关行">
              <!-- <a-select showSearch style="width: 100%" v-model="form.custom_com">
                <a-select-option v-for="op in customOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select> -->
              <a-auto-complete
                v-model="form.custom_com"
                style="width: 100%"
                placeholder="请选择报关行"
                :filterOption="handleAutoSearch"
              >
                <template slot="dataSource">
                  <a-select-option v-for="op in customOps" :key="op.name">{{ op.name }}</a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="报关日期">
              <a-date-picker v-model="form.ecd" class="full-width"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :xxl="18" :lg="21" :md="24">
            <a-form-model-item label="报关单号">
              <template v-for="(item, index) in form.custom_num">
                <a-input-group compact :key="index" class="box-item">
                  <a-input v-model="form.custom_num[index]" style="width: 200px"/>
                  <a-icon type="close-circle" theme="filled"
                          class="box-item_del" v-if="form.custom_num.length > 1"
                          @click="delItem('custom_num', index)"/>
                  <a-button type="primary" v-if="index === (form.custom_num.length - 1)"
                            style="width: 60px;margin-left: 10px" @click="createItem('custom_num')">添加</a-button>
                </a-input-group>
              </template>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col v-bind="colSpan">
            <a-form-model-item label="车队/仓库">
              <a-input v-model="form.trans_com"/>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="送货时间">
              <a-date-picker showTime v-model="form.esd" class="full-width"/>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="colSpan">
            <a-form-model-item label="提货时间">
              <a-date-picker showTime v-model="form.edd" class="full-width"/>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="18" :lg="21" :md="24">
            <a-form-model-item label="提货地址">
              <template v-for="(item, index) in form.delivery_address">
                <a-input-group compact :key="index" class="address-input">
                  <a-input v-model="form.delivery_address[index]" style="width: 80%"/>
                  <a-icon type="close-circle" theme="filled"
                          class="item_del" v-if="form.delivery_address.length > 1"
                          @click="delItem('delivery_address', index)"/>
                  <a-button type="primary" v-if="index === (form.delivery_address.length - 1)"
                            style="width: 60px;margin-left: 10px" @click="createItem('delivery_address')">新增</a-button>
                </a-input-group>
              </template>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="18" :lg="21" :md="24">
            <a-form-model-item label="送货地址">
              <template v-for="(item, index) in form.send_address">
                <a-input-group compact :key="index" class="address-input">
                  <a-input v-model="form.send_address[index]" style="width: 80%"/>
                  <a-icon type="close-circle" theme="filled"
                          class="item_del" v-if="form.send_address.length > 1"
                          @click="delItem('send_address', index)"/>
                  <a-button type="primary" v-if="index === (form.send_address.length - 1)"
                            style="width: 60px;margin-left: 10px" @click="createItem('send_address')">新增</a-button>
                </a-input-group>
              </template>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="18" :lg="21" :md="24">
            <a-form-model-item label="车牌号">
              <template v-for="(item, index) in form.car_num">
                <a-input-group compact :key="index" class="box-item">
                  <a-input v-model="form.car_num[index]" style="width: 200px"/>
                  <a-icon type="close-circle" theme="filled"
                          class="box-item_del" v-if="form.car_num.length > 1"
                          @click="delItem('car_num', index)"/>
                  <a-button type="primary" v-if="index === (form.car_num.length - 1)"
                            style="width: 60px;margin-left: 10px" @click="createItem('car_num')">添加</a-button>
                </a-input-group>
              </template>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card class="card" title="备注" :bordered="false">
        <a-row :gutter="20">
          <a-col :xxl="18" :lg="21" :md="24">
            <a-form-model-item label="">
              <a-textarea v-model="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card class="card" title="费用信息" :bordered="false">
        <a-tabs default-active-key="a">
          <a-tab-pane key="a" tab="应收费用">
            <edit-table
              scroll
              :columns="chargeFeeColumns"
              :sourceData="chargeFeeData"
              :feeStatusFlag="true"
              @getTableDate="getChargeFeeData"
              @getTableDateError="getTableDateError"></edit-table>
          </a-tab-pane>
          <a-tab-pane key="b" tab="应付费用">
            <edit-table
              scroll
              ref="order_pay_fee"
              :columns="payFeeColumns"
              :sourceData="payFeeData"
              :feeStatusFlag="true"
              @getTableDate="getPayFeeData"
              @getTableDateError="getTableDateError"></edit-table>
          </a-tab-pane>
          <template slot="tabBarExtraContent">
            <template>
              <span style="margin-right: 20px">应收：{{ totalChargeFee.toFixed(2) }} </span>
              <span style="margin-right: 20px"> 应付：{{ totalPayFee.toFixed(2) }} </span>
              <span style="margin-right: 100px"> 利润：{{ (totalChargeFee - totalPayFee).toFixed(2) }} </span>
              <!--<a-button type="primary" :disabled="!isEdit">
                现金收费
              </a-button>-->
            </template>
          </template>
        </a-tabs>
      </a-card>
    </a-form-model>
  </div>
</template>

<script>
import { getCommonOptions, getCustomer, getSupplier } from '@/api/common'
import { createItmsOrderInfo, getItmsOrderDetail, getItmsMetaOption } from '@/api/itms'
import { EditTable } from '@/components'
import moment from 'moment';
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce';
export default {
  components: {
    EditTable, preventReClick
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      customerInfo: undefined,
      fetching: false,
      colSpan: {
        xxl: 6,
        lg: 7,
        md: 8
      },
      form: {},
      disabled: false,
      isEdit: false,
      sourceForm: {
        id: '',
        serial_num: '',
        quota_num: '',
        auth_agency: null,
        customer_num: null,
        importer: '',
        exporter: '',
        total_num: null,
        unit: '',
        weight: null,
        volume: null,
        total_day: '',
        custom_com: '',
        ecd: null,
        custom_num: [''],
        trans_com: null,
        esd: null,
        edd: null,
        delivery_address: [''],
        send_address: [''],
        car_num: [''],
        remark: ''
      },
      rules: {
        // 报价编号
        quota_num: [{ required: true, message: '请输入报价编号', trigger: 'blur' }]
      },
      agencyOps: [],
      customOps: [], // 报关行
      packageTypeOps: [],
      chargeFeeData: [],
      payFeeData: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 1 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 1,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预收',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预收', value: 1 },
              { name: '预收', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      payFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 2 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 2,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalPay,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款对象',
          dataIndex: 'pay_target_id',
          extra: {
            type: 'select',
            func: getSupplier,
            params: [ { department: 2 } ],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预付', value: 1 },
              { name: '预付', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      // 应收金额统计展示
      totalChargeFee: 0,
      // 应付金额统计展示
      totalPayFee: 0
    }
  },
   watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'LogisticsCUR') { // 编辑页路由监控
        this.handleFormReset()
        if (newRoute.params.id === 'create') { // 新增
          this.isEdit = false
          this.$route.meta.title = '新增物流园报关'
          this.$refs.form.clearValidate()
        } else {
          this.disabled = newRoute.query.disabled
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  created() {
    this.handleFormReset()
    this.getBasicData()
    if (this.$route.params.id !== 'create') {
      this.disabled = this.$route.query.disabled !== 'false' && this.$route.query.disabled
      this.getEditData(this.$route.params.id)
    }
  },
  methods: {
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该订单不可删除！'
      })
    },
    moment,
    handleFormReset() {
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      this.customerInfo = undefined
      this.chargeFeeData = []
      this.payFeeData = []
      this.totalChargeFee = 0;
      this.totalPayFee = 0;
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
      })
    },
    getEditData(id) {
      this.isEdit = true
      this.$route.meta.title = '编辑物流园报关'
      getItmsOrderDetail({ id: id }).then(v => {
        this.form = {
          ...v.order,
          ...v.content,
          id: id,
          ecd: v.content['ecd'] ? moment(v.content['ecd'], 'YYYY/MM/DD') : null,
          esd: v.content['esd'] ? moment(v.content['esd'], 'YYYY/MM/DD') : null,
          edd: v.content['edd'] ? moment(v.content['edd'], 'YYYY/MM/DD') : null,
          custom_num: v.order['custom_num'] || [''],
          delivery_address: v.content['delivery_address'] || [''],
          send_address: v.content['send_address'] || [''],
          car_num: v.content['car_num'] || ['']
        }
        v.charge_fees.forEach(fee => {
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        v.pay_fees.forEach(fee => {
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        this.customerInfo = {
          key: v.order.auth_agency,
          label: v.order.agency_name
        }
        this.chargeFeeData = v.charge_fees
        this.chargeFeeData.forEach(v => {
          this.totalChargeFee += parseFloat(v.money);
        });
        this.payFeeData = v.pay_fees
        this.payFeeData.forEach(v => {
          this.totalPayFee += parseFloat(v.money);
        });
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agencyOps = [];
      this.fetching = true;
      getCustomer({
        department: 2,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agencyOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['auth_agency'] = value.key
      this.form['agency_name'] = value.label
      this.agencyOps = []
      this.fetching = false
    },
    getBasicData() {
      // getCommonOptions('customer').then(v => {
      //   this.agencyOps = v
      // })
      getCommonOptions('common_type', { db_type: 3 }).then(v => {
        this.packageTypeOps = v
      })
      getItmsMetaOption('itms_role', { role_type: 3 }).then(v => {
        this.customOps = v
      })
    },
    createItem(field) { // 增加更多箱型
      this.form[field].push('')
    },
    delItem(field, index) {
      this.form[field].splice(index, 1)
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    getPayFeeData(data) {
      this.payFeeData = data
    },
    calcTotalCharge(value, newData) {
      let money = 0;
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.money)
      });
      this.totalChargeFee = money;
      return newData;
    },
    calcTotalPay(value, newData) {
      let money = 0;
      this.payFeeData.forEach(v => {
        money += parseFloat(v.money)
      });
      this.totalPayFee = money;
      return newData
    },
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const orderKeys = ['id', 'serial_num', 'quota_num', 'auth_agency', 'agency_name', 'customer_num', 'importer', 'exporter', 'custom_num']
          const contentKeys = ['total_num', 'unit', 'weight', 'volume', 'total_day', 'custom_com',
            'ecd', 'trans_com', 'esd', 'edd', 'delivery_address', 'send_address', 'car_num', 'remark']
          const tempData = {
            ...this.form,
            ecd: this.form.ecd ? moment(this.form.ecd).valueOf() : null,
            esd: this.form.esd ? moment(this.form.esd).valueOf() : null,
            edd: this.form.edd ? moment(this.form.edd).valueOf() : null
          }
          const orderData = {}
          const contentData = {}
          for (const key in tempData) {
            if (orderKeys.includes(key)) {
              orderData[key] = tempData[key]
            } else if (contentKeys.includes(key)) {
              contentData[key] = tempData[key]
            }
          }
          const data = {
            order: orderData,
            content: contentData,
            charge_fees: this.chargeFeeData,
            pay_fees: this.payFeeData
          }
          createItmsOrderInfo('save_custom', data).then(_ => {
              this.$notification['success']({
                message: '提示',
                description: this.isEdit ? '编辑成功' : '创建成功.'
              })
              this.$router.push({
                name: 'Logistics',
                params: {
                  order: orderData,
                  content: contentData
                },
                query: {
                  isEdit: this.isEdit
                }
              })
            })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style>
  .pc .ant-drawer{
    display: none;
  }
  .pc .ant-drawer-open{
    display: block;
  }
</style>

<style scoped>
  .card {
    margin-bottom:10px;
  }
  .table-title{
    margin-bottom: 10px;
    display: inline-block;
    font-weight: 800;
    /* color: #1890ff */
  }
  .box-item{
    position: relative;
    width:200px;
    margin-right: 10px;
    margin-top: 10px;
    color: red;
  }
  .box-item:last-child{
    width: 300px;
  }
  .box-item_del{
    position: absolute;
    top: -5px;
    left: 190px;
    color: #ccc;
    z-index: 999;
    cursor: pointer;
  }
  .box-item_del:hover{
    color: #1890ff;
  }
  .address-input {
    position: relative;
    margin-bottom: 10px;
  }
  .address-input .item_del{
    position: absolute;
    top: -5px;
    margin-left: -5px;
    color: #ccc;
    z-index: 999;
    cursor: pointer;
  }
</style>
